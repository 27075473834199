<template>
  <v-container fluid>
    <v-row>
      <!--left img-->
      <v-col
        class="left-img hidden-md-and-down"
        cols="0"
        lg="8"
      />

      <!--right content-->
      <v-col
        cols="12"
        lg="4"
      >
        <v-sheet height="100vh">
          <slot />
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AuthWrapper'
};
</script>

<style lang='scss'>
.left-img {
  background-image: url('../../assets/images/bg/login-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  object-fit: cover;
  height: auto !important;
}
</style>
